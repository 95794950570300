<template>
  <v-app>
    <div
      style="height: 100%;display: flex;justify-content: center;align-items: center;padding: 10px"
      :style="{backgroundColor: this.$wikaz.getInstance().theme.colors.backgroundlogin}"
    >
      <v-card
        class="elevation-12"
        style="width: 400px"
      >
        <v-toolbar
          color="#456173"
          dense
        >
          <v-toolbar-title style="color: white">
            Récupération de mot de passe
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="recoveryDetails"
            v-model="valid"
          >
            <v-text-field
              v-model="recoveryDetails.email"
              dense
              outlined
              label="Email"
              name="login"
              prepend-icon="person"
              type="text"
              :rules="emailRules"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="success"
            @click.prevent="sendEmail"
          >
            ENVOYER EMAIL DE RECUPERATION
          </v-btn>
          <v-spacer />
        </v-card-actions>
        <v-card-actions>
          <router-link :to="{ name: 'login' }">
            Retour à l'identification
          </router-link>
        </v-card-actions>
      </v-card>
    </div>
  </v-app>
</template>
<style scoped lang="scss">
    .vx-recovery-container{
        display:flex;
        flex-direction: column;
        margin:auto!important;
        padding:0;
        height:inherit;
        width:inherit;
        max-width:inherit;
        background-color: rgb(255,255,255);
        .ivu-spin-large{
            background-color: rgba(200,200,200,.4);
        }
        .myLogo{
            margin: 16px;
        }
        .panel{
            background-color: #3F51B5;
            box-shadow:2px 2px 4px 0 rgba(0, 0, 0, 0.5);
            height: auto;
            width:350px;
            display:flex;
            flex-direction: column;
            position: relative;
            margin: 10% auto auto;
            border: none;
            .panel-header{
                border-bottom: 4px solid #fff;
                h4{
                    font-size:.8rem;
                    color: #fff;
                    margin:6px auto;
                    text-align: center;
                    font-weight:bold;
                    text-transform: uppercase;
                }
            }
            .panel-body{
                width:100%;
                display:flex;
                margin:8px 0;
                justify-content: space-between;
                .form-horizontal{
                    margin:auto 0;
                    justify-content: space-between;
                    min-width:100%;
                    .form-group{
                        width: 80%;
                        margin: 4px auto !important;
                        a{
                            display:flex;
                            align-items: center;
                            text-align: center;
                            justify-content: center;
                            i{
                            }
                            &:hover{
                                text-decoration:  none;
                            }
                        }
                        input:-webkit-autofill,input{
                            border-color: #555;
                            background-color: rgb(255,255,255) !important;
                            -webkit-box-shadow: 0 0 0 30px white inset;
                        }
                        .ivu-form-item-error-tip{
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    @media (min-width:1680px) {
        .recov-page {
            .panel {
                margin-top: 168px;
            }
        }
    }
</style>
<script>
export default {
    name: 'VxRecovery',
    data () {
        return {
            valid: true,
            recoveryDetails: { email: '' },
            emailRules: [
                v => !! v || 'E-mail est requis',
                v => /.+@.+\..+/.test(v) || 'E-mail non valide',
            ],
        }
    },
    mounted () {
        this.recoveryDetails.email = this.$route.query.email || this.$route.params.email
    },
    methods: {
        sendEmail () {
            if (this.$refs.recoveryDetails.validate()) {
                this.$wikaz.request.get('api/wikaz/recovery?email=' + this.recoveryDetails.email).then(() => {
                    this.$refs.recoveryDetails.reset()
                })
            } else {
                this.$wikaz.outils.notification.showWarning('Merci de renseigner une adresse Email valide!', 5.5)
            }
        },
    },
}
</script>